import { useDispatch, useSelector } from 'react-redux';
import { MapHookState, MapHookResult } from './UseMap.hook.types';
import { setMapDataAction, resetMapAction } from '../../Redux/Slices/Map/Map.slice';
import { API_URL } from '../../Constants/Constants';

export const useMap = (): MapHookResult => {
    const center = useSelector((state: {map: MapHookState}) => state.map.center);
    const zoom = useSelector((state: {map: MapHookState}) => state.map.zoom);
    const maxZoom = useSelector((state: {map: MapHookState}) => state.map.maxZoom);
    const markers = useSelector((state: {map: MapHookState}) => state.map.markers);
    const dispatch = useDispatch();

    // Request Map Data
    const requestMapData = (query: string = '') => {
        fetch(`${API_URL}/maps/get_data${query}`, {
            credentials: 'include'
        })
        .then((response) => response.json())
        .then((result) => {
            if (result && result.data) {
                dispatch(setMapDataAction(result.data));
            }
        });
    };

    // Reset
    const reset = () => {
        dispatch(resetMapAction());
    };

    return {
        center,
        zoom,
        maxZoom,
        markers,
        requestMapData,
        reset
    };
};