import { FC, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../../Hooks/UseAuth/UseAuth.hook';
import { useAccounts } from '../../Hooks/Accounts/Accounts.hook';
import { AppLayout } from '../Complex/AppLayout/AppLayout.com';

const authPages = [
    '/login',
    '/registration'
];

// AuthProvider Component
export const AuthProvider: FC = () => {

    const { isAuth } = useAuth();
    const { selected: selectedAccount = '' } = useAccounts();
    const location: any = useLocation();
    const isAuthPage = authPages.includes(location.pathname);
    const queryString = location.search;

    useEffect(() => {
        if (!isAuth && !isAuthPage && queryString) {
            sessionStorage.setItem('redirectQuery', queryString);
        }
    }, [isAuth, isAuthPage, queryString]);

    const savedQuery = sessionStorage.getItem('redirectQuery') || '';
    
    // Redirect to login page if user is not authenticated
    if(!isAuth && !isAuthPage) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    // Redirect to dashboard if user is authenticated
    if(isAuth && isAuthPage) {
        let from = (location.state && location.state.hasOwnProperty('from')) ? location.state.from.pathname : "/";
        sessionStorage.removeItem('redirectQuery');
        return <Navigate to={`${from}${savedQuery}`} replace />;
    }

    // Redirect to accounts page if user is authenticated and has no selected account
    if(isAuth && !selectedAccount && location.pathname !== '/accounts') {
        return <Navigate to={`/accounts${savedQuery}`} replace />;
    }

    // Render Auth Pages
    if(isAuthPage) {
        return <Outlet />;
    }

    // Render the app layout
    return <AppLayout><Outlet /></AppLayout>;
};