import { FC, memo, useCallback, useState } from "react";
import { debounce } from 'lodash';
import { Col } from "../../../../Simple/Layout/Flex/Col/Col.com";
import { Input } from "../../../../Simple/Form/Input/Input.com";
import { Text } from "../../../../Simple/Text/Text.com";
import { Row } from "../../../../Simple/Layout/Flex/Row/Row.com";
import css from "./SearchFilters.module.scss";

type Props = {
    onSponsorSearch: (sponsorSearch: string) => void;
    onConditionsSearch: (conditionsSearch: string) => void;
};

// SearchFilters Component
export const SearchFilters: FC<Props> = memo((props: Props) => {

    // Props
    const { onSponsorSearch, onConditionsSearch } = props;

    // States
    const [sponsorSearch, setSponsorSearch] = useState<string>('');
    const [conditionsSearch, setConditionsSearch] = useState<string>('');

    // Debounced Handlers
    const debouncedSponsorSearch = useCallback(
        debounce((value: string) => {
            onSponsorSearch(value);
        }, 300),
        [onSponsorSearch]
    );

    const debouncedConditionsSearch = useCallback(
        debounce((value: string) => {
            onConditionsSearch(value);
        }, 300),
        [onConditionsSearch]
    );

    // Handle Sponsor Search
    const handleSponsorSearch = (name: string, value: string) => {
        setSponsorSearch(value);
        debouncedSponsorSearch(value);
    };

    // Handle Conditions Search
    const handleConditionsSearch = (name: string, value: string) => {
        setConditionsSearch(value);
        debouncedConditionsSearch(value);
    };

    return (
        <Col className={css.SearchFilters}>

            {/* Sponsor */}
            <Col paddingBottom="1">
                <Row paddingBottom="0.25">
                    <Text size="0" bold={true}>Sponsor:</Text>
                </Row>

                <Row>
                    <Input name="sponsorSearch" placeholder="Sponsor" value={sponsorSearch} onChange={handleSponsorSearch} />
                </Row>
            </Col>

            {/* Conditions */}
            <Col>
                <Row paddingBottom="0.25">
                    <Text size="0" bold={true}>Conditions:</Text>
                </Row>

                <Row>
                    <Input name="conditionsSearch" placeholder="Conditions" value={conditionsSearch} onChange={handleConditionsSearch} />
                </Row>
            </Col>
        </Col>
    )
});