import { FC, memo, useEffect, useState } from "react";
import { useMap } from "react-leaflet";

// ZoomLevelDisplay Component
export const ZoomLevelDisplay: FC = memo(() => {
    const map = useMap();

    // States
    const [zoom, setZoom] = useState(map.getZoom());

    useEffect(() => {
        const handleZoom = () => {
            setZoom(map.getZoom());
        };

        map.on('zoomend', handleZoom);

        return () => {
            map.off('zoomend', handleZoom);
        };
    }, [map]);

    return (
        <div
            style={{
                position: 'absolute',
                top: '85px',
                left: '10px',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                padding: '10px',
                borderRadius: '6px',
                zIndex: 1000,
            }}
        >
            <strong>Zoom Level:</strong> {zoom}
        </div>
    );
});