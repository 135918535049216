import { FC, memo, CSSProperties, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row } from "../../../Simple/Layout/Flex/Row/Row.com";
import { Text } from "../../../Simple/Text/Text.com";
import { Icon } from "../../../Simple/Icon/Icon.com";

type Props = {
    style?: CSSProperties;
    className?: string;
};

// NearLavaa Component
export const NearLavaa: FC<Props> = memo((props: Props) => {

    // Hooks
    const location = useLocation();
    const navigate = useNavigate();

    // States
    const [checked, setChecked] = useState<boolean>(false);

    // Handle Click
    const handleClick = () => {
        const updateChecked = !checked;
        const queryParams = new URLSearchParams(location.search);

        // Update query params
        queryParams.set('nearLavaa', updateChecked.toString());

        // Update state
        setChecked(updateChecked);

        // Navigate
        navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const nearLavaa = queryParams.get('nearLavaa');
        if (nearLavaa) {
            nearLavaa === 'true' && setChecked(true);
            nearLavaa === 'false' && setChecked(false);
        }
    }, [location.search]);

    return (
        <Row alignitems="center" style={{position: 'absolute', top: 140, left: 10, zIndex: 1000, borderRadius: 6, padding: 10, backgroundColor: 'rgba(255, 255, 255, 0.8)'}}>
            <Row>
                <Text size="0" bold={true}>Near Lavaa:</Text>
            </Row>

            <Row paddingLeft="0.5">
                <Icon name={checked ? 'checked' : 'unchecked'} onClick={handleClick} />
            </Row>
        </Row>
    )
});