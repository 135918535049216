import { FC, useEffect, useState } from "react";
import { useMap } from "react-leaflet";

type Props = {
    onZoomThresholdCross: (currentZoom: number) => void;
}

export const ZoomHandler: FC<Props> = (props) => {

    // Props
    const { onZoomThresholdCross } = props;

    // Hooks
    const map = useMap();

    // States
    const [prevZoom, setPrevZoom] = useState(map.getZoom());

    useEffect(() => {
        const handleZoomEnd = () => {
            const currentZoom = map.getZoom();

            // Check if the zoom threshold is crossed
            if ((prevZoom <= 10 && currentZoom > 10) || (prevZoom > 10 && currentZoom <= 10)) {
                onZoomThresholdCross(currentZoom);
            }

            // Update prevZoom
            setPrevZoom(currentZoom);
        };

        map.on('zoomend', handleZoomEnd);

        return () => {
            map.off('zoomend', handleZoomEnd);
        };
    }, [map, prevZoom, onZoomThresholdCross]);

    return null;
};