import { FC, memo, useEffect } from "react";
import { useMap } from "react-leaflet";

type Props = {
    center: {lat: number; lng: number};
    zoom: number;
};

// DynamicCenter Component
export const DynamicCenter: FC<Props> = memo((props) => {

    // Props
    const { center, zoom } = props;

    // Hooks
    const map = useMap();

    // Set Map Center
    useEffect(() => {
        if (center.lat && center.lng) {
            const currentCenter = map.getCenter();
            if (currentCenter.lat !== center.lat || currentCenter.lng !== center.lng) {
                map.setView([center.lat, center.lng], zoom);
            }
        }
    }, [center, zoom, map]);

    return null;
});